.db-navbar {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background-color: #ffffff;

  .db-navbar-logo-section {
    display: flex;
    width: 150px;

    .menu-icon {
      font-size: 20px;
      cursor: pointer;
    }

    .db-logo-text {
      font-weight: bold;
      color: #323232;
      padding: 0;
      margin: 0;
    }

    .sidenav {
      height: 100%;
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      background-color: #ffffff;
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 30px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

      .sidenav-parent {
        list-style-type: none;
        padding: 0;
        margin: 0 0 55px 0;

        li {
          font-size: 15px;
          color: #323232;
          padding: 10px 15px;

          .expand-collections{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .expand-icon{
              margin-right: 19px;
              font-size: large;
              cursor: pointer;
            }
            .expand-icon.expanded{
              transform: rotate(-180deg);
            }
          }

          & > a {
            display: block;
            color: #323232;
            padding: 10px 15px;
            margin: -10px -15px;

            &:hover, &.active {
              background-color: #d5d5d5;
            }
          }

          .sidenav-child.expand{
            display: block;
          }

          .sidenav-child {
            list-style-type: none;
            margin: 10px 0 0 0;
            padding: 0;
            display: none;

            li {
              > a {
                padding: 10px 30px;
                margin-left: -30px !important;
                margin-right: -30px !important;
                display: flex;
                justify-content: space-between;

                div:first-child {
                  width: 80%;
                  overflow: hidden;
                }

                div {
                  .config-badge {
                    border-radius: 50%;
                    border: 1px solid black;
                    display: flex;
                    // background-color: #eaeaea;
                    height: 15px;
                    width: 15px;
                    justify-content: center;
                    align-items: center;
                    font-size: smaller;
                  }

                  .config-badge.admin-configured {
                    // border-color: black;
                  }

                  .config-badge.user-configured {
                    border-color: black;
                    background-color: black;
                  }
                }
              }


            }
          }
        }
      }

      a {
        //padding: 8px 8px 8px 32px;
        text-decoration: none;
        //font-size: 25px;
        //color: #818181;
        //display: block;
        //transition: 0.3s;
        //
        //&:hover {
        //  color: #f1f1f1;
        //}
      }

      .close-icon-wrapper {
        display: block;
        width: 260px;
        background-color: #ffffff;
        position: fixed;
        top: 0;
        z-index: 11;
        transition: 0.5s;
        text-align: end;
        padding: 10px 12px;

        .close-icon {
          font-size: 20px;
          color: #323232;
          cursor: pointer;
        }
      }
    }
  }

  .db-navbar-menu-section {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .settings-icon, .user-icon {
      color: #323232;
      font-size: 20px;
    }

    .user-dropdown {
      position: relative;
      display: inline-block;
    }

    .user-dropdown-content {
      display: none;
      position: absolute;
      background-color: #ffffff;
      min-width: 150px;
      //padding: 12px 16px;
      z-index: 1;
      right: -10px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border-radius: 3px;

      .user-detail-section {
        padding: 12px 16px;
        border-bottom: 1px solid gray;

        .user-name {
          font-size: 16px;
          padding: 0;
          margin: 0;
        }

        .user-role {
          font-size: 11px;
          padding: 0;
          margin: 2px 0 0 0;
        }
      }

      .links-section {

        p {
          margin: 0;
          padding: 12px 16px;
        }

        p:hover {
          background-color: #dedede;
        }
      }

    }

    .user-dropdown:hover .user-dropdown-content {
      display: block;
    }
  }
}